<template>
  <b-sidebar
    id="add-new-form-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    width="100%"
    shadow
    backdrop
    header-class="header-slide"
    title="TRẢ KẾT QUẢ XÉT NGHIỆM"
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
    @shown="handleOpen"
  >
    <template #default>
      <!-- Loading  -->
      <div
        class="justify-center items-center flex h-100"
        style="flex: auto"
        v-if="isLoading"
      >
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
      <div v-else>
        <div class="flex">
          <div class="side-bar">
            <!-- <b-form-input
              v-model="keyword"
              :placeholder="$t('lbl_search')"
              :debounce="500"
              class="mb-2"
            ></b-form-input>-->

            <div class="orders" id="orders">
              <div
                v-for="item in listOrders"
                :key="item.id"
                :style="
                  dataSelected.ID === item.ID ? 'background: #f0f8ff' : ''
                "
                class="order-item flex items-center justify-between"
                @click="handleView(item)"
              >
                <div>
                  <p class="uppercase mb-0">
                    <b>{{ item.contact_name }}</b>
                  </p>
                  <small>
                    Đặt lúc:
                    <b style="font-size: 13px">{{
                      appUtils.formatDateTime(item.CreatedAt)
                    }}</b>
                  </small>
                </div>
              </div>
              <!-- Loading order -->
              <div
                class="justify-center items-center flex w-100"
                style="height: 40px"
                v-show="isloadingOrder"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </div>
          </div>

          <div class="content-form">
            <!-- Thông tin đơn hàng -->
            <div
              class="order-info flex items-center justify-between text-base px-4"
            >
              <span>
                <b>Đơn hàng:</b>
                {{ dataSelected.supplier_order_code }} -
                {{ dataSelected.contact_name }}
              </span>
              <span>
                <b>Thời gian đặt:</b>
                {{ appUtils.formatDateTime(dataSelected.CreatedAt) }}
              </span>
            </div>
            <!-- Kết quả -->
            <div class="generate-result flex w-100 p-2">
              <div class="list-service flex-2 mr-2">
                <!-- Chọn dịch vụ để generate phiếu KQ -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="listServicesSelected"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-2a"
                    stacked
                  >
                    <div v-for="item in listServices" :key="item.id">
                      <b-form-checkbox
                        :value="item"
                        class="mt-1"
                        :disabled="item.isDisable"
                      >
                        <span
                          class="cursor-pointer"
                          v-b-tooltip.hover.right="
                            item.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
                              ? 'Đơn vị xử lý đã từ chối, không thể trả kết quả'
                              : item.isDisable
                              ? 'Đã tạo phiếu kết quả'
                              : ''
                          "
                          >{{ item.order_item_name }}</span
                        >
                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox-group>
                  <div class="mt-2 flex justify-content-end px-2">
                    <b-button
                      :disabled="!listServicesSelected.length"
                      variant="outline-primary"
                      class="cursor-pointer"
                      :class="
                        !listServicesSelected.length ? 'cursor-not-allowed' : ''
                      "
                      @click="generateInputUploadFile"
                      >Upload phiếu kết quả</b-button
                    >
                  </div>
                </b-form-group>
              </div>
              <!-- Loading trả KQ -->
              <div
                class="justify-center items-center flex flex-3"
                v-if="isLoadingReturnResult"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
              <!-- Kết quả -->
              <div class="result-form flex-3" v-else>
                <!-- Lưu ý -->
                <p v-if="formUpload.length > 0" class="text-danger font-bold">
                  Lưu ý: Bệnh nhân sẽ nhận được Phiếu kết quả được cập nhật/bổ
                  sung gần nhất
                </p>

                <div
                  v-if="formUpload.length > 0"
                  class="flex flex-col gap-4 pb-3"
                >
                  <div v-for="(item, index) in formUpload" :key="index">
                    <div>
                      <div class="flex align-items-end justify-between mb-1">
                        <!-- Tên phiếu -->
                        <b style="font-size: 16px; line-height: 24px">
                          Phiếu kết quả
                          <!-- <span class="form-name">{{ item.name }}</span> -->
                        </b>
                        <!-- Huỷ phiếu => generate lại -->
                        <small
                          class="btn-cancel cursor-pointer user-select-none"
                          style="min-width: 75px"
                          v-if="item.results.length > 0 && !item.id"
                          @click="cancelForm(index)"
                          >x Huỷ phiếu</small
                        >
                      </div>
                      <ul
                        v-for="(order_item, o_index) in item.order_items"
                        :key="o_index"
                      >
                        <li>{{ order_item.service_name }}</li>
                      </ul>
                      <!-- Khi chưa có KQ  -->
                      <div
                        class="flex items-center gap-2 w-100"
                        style="margin-top: 4px; margin-bottom: 8px"
                        v-for="(result, resultIndex) in item.results"
                        :key="`${index}-${resultIndex}`"
                      >
                        <div
                          v-if="!result.id"
                          class="w-100 flex items-center gap-2"
                        >
                          <b-form-file
                            :ref="`uploadResult${resultIndex}`"
                            v-model="result.file"
                            :placeholder="$t('lbl_upload')"
                            accept
                            no-drop
                            :browse-text="$t('lbl_choose_file')"
                            class="cs-select-file cursor-pointer"
                          ></b-form-file>
                          <small
                            class="delete cursor-pointer"
                            v-if="item.results.length !== 1"
                            @click="deleteForm(item.results, resultIndex)"
                          >
                            <feather-icon
                              class="cursor-pointer"
                              icon="XIcon"
                              size="20"
                            />
                          </small>
                        </div>
                        <!-- Khi dã có KQ -->
                        <div
                          v-else
                          class="w-full result-submitted flex items-center w-full justify-between"
                        >
                          <div>
                            <div class="flex flex-col gap-2">
                              <div class="flex gap-2 items-center">
                                <feather-icon
                                  icon="LinkIcon"
                                  size="16"
                                ></feather-icon>
                                <span
                                  class="url"
                                  @click="openFile(result.url)"
                                  >{{ formatFileName(result.url) }}</span
                                >
                              </div>
                              <small>
                                {{ resultIndex === 0 ? "Upload " : "Bổ sung " }}
                                bởi
                                <b>{{ result.updated_name }}</b>
                                vào lúc
                                <b>{{
                                  appUtils.formatDateTime(result.created_at)
                                }}</b>
                              </small>
                            </div>
                          </div>
                          <!-- <feather-icon icon="CheckCircleIcon" size="20" color="#28c76f"></feather-icon> -->
                          <!-- <span class="text-success" style="font-weight: 500">Đã gửi</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- Bổ sung KQ: Mỗi lần chỉ được bổ sung 1 file KQ -->
                    <div
                      class="btn-cancel cursor-pointer text-blue"
                      @click="addResult(item)"
                      v-if="!isAddMoreResult(item)"
                    >
                      + Bổ sung kết quả
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer="{ hide }">
      <div
        class="flex align-items-center px-2 py-1 justify-content-end gap-3 footer"
      >
        <b-button variant="outline-secondary" @click="hide">Đóng</b-button>
        <b-button
          variant="primary"
          @click="onSubmit"
          :busy="isLoadingReturnResult"
          :disabled="formUpload.length == 0"
          >Gửi kết quả</b-button
        >
      </div>
    </template>
  </b-sidebar>
</template>

  <script>
import appUtils from "@/utils/appUtils";
import {
  ORDER_FORM_PROCESSOR_STATUS,
  RESULT_TYPE,
  TYPE_RETURN_RESULT,
} from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BSidebar } from "bootstrap-vue";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import { mapState } from "vuex";

export default {
  components: {
    BSidebar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active",
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedPage: {
      type: Number,
    },
    orderId: {
      type: Number,
    },
  },
  data() {
    return {
      appUtils,
      listServicesSelected: [],
      listServices: [],
      formUpload: [],
      listDisabled: [],
      isLoading: false,
      isloadingOrder: false,
      isLoadingReturnResult: false,
      detailResult: [],
      keyword: "",
      listOrders: [],
      dataOrders: [],
      dataSelected: {},
      processorOrdersSelected: [],
      pagination: {
        page_size: 10,
        page_num: 1,
        total: 0,
        total_page: 0,
      },
      TYPE_RETURN_RESULT,
      RESULT_TYPE,
      ORDER_FORM_PROCESSOR_STATUS,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
  },
  watch: {
    // disable những dịch vụ đã generate phiếu
    listDisabled(value) {
      this.listServices = this.listServices?.map((elm) => {
        return {
          ...elm,
          isDisable: value.find((item) => item.id === elm.id) ? true : false,
        };
      });
    },
    dataSelected: {
      async handler() {
        this.listServices = this.processorOrdersSelected;
      },
    },
  },
  methods: {
    // mở file kết quả
    openFile(file) {
      window.open(file);
    },
    // Đóng slidebar => reset form
    resetForm() {
      this.listServicesSelected = [];
      this.formUpload = [];
      this.listDisabled = [];
    },
    // Thêm 1 ô input tải file kết quả khi chọn dịch vụ
    generateInputUploadFile() {
      this.formUpload.push({
        // id: new Date().getTime(),
        name: this.listServicesSelected
          ?.map((elm) => this.formatName(elm.order_item_name))
          ?.toString(),
        results: [
          {
            file: null,
          },
        ],
        listServices: this.listServicesSelected?.map(
          (elm) => elm.data?.order_item_attachments[0]?.ID
        ),
        order_items: this.listServicesSelected?.map((elm) => {
          return {
            id: elm.id,
            service_name: elm.order_item_name,
          };
        }),
      });
      let serviveRejected = this.listServices.filter(
        (elm) => elm.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
      );
      this.listDisabled.push(...this.listServicesSelected);
      this.listDisabled = this.listDisabled.concat(serviveRejected);
      this.listServicesSelected = [];
    },
    // Check nút bổ sung kết quả
    isAddMoreResult(form) {
      return form.results?.filter((elm) => !elm.id).length === 1;
    },
    // Thêm 1 ô input tải file kết quả khi ấn bổ sung kết quả
    addResult(form) {
      if (this.isAddMoreResult(form)) return;
      else {
        form.results.push({
          file: null,
        });
        this.listServicesSelected = [];
      }
    },
    // generate name: Viết tắt các chữ cái đầu
    formatName(name) {
      const words = name.split(" ");
      let initials = "";
      words.forEach((word) => {
        initials += word.replace("(", "").replace("[", "").charAt(0);
      });
      initials = initials.toUpperCase();
      return ` ${initials}`;
    },
    // Xoá ô input tải file lên
    deleteForm(form, index) {
      form.splice(index, 1);
    },
    // Huỷ phiếu đã generate
    cancelForm(index) {
      this.listDisabled = this.listDisabled?.filter((elm) => {
        return (
          this.formUpload[index].listServices?.includes(
            elm.data?.order_item_attachments[0]?.ID
          ) === false || elm.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
        );
      });
      this.formUpload.splice(index, 1);
    },
    // submit form
    async onSubmit() {
      try {
        let attachments = [];
        // thông báo khi có ô input chưa tải file lên
        if (
          this.formUpload.filter((elm) => {
            return (
              elm.results.filter((item) => !item.id && item.file === null)
                .length > 0
            );
          }).length > 0
        ) {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Bạn cần tải lên file kết quả",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return;
        }
        // Nếu đã tải file đầy đủ
        else {
          this.isLoadingReturnResult = true;
          console.log(this.formUpload);
          // upload những file mới tải lên
          for (let i = 0; i < this.formUpload.length; i++) {
            if (this.formUpload[i].results?.find((elm) => !elm.id)) {
              await this.uploadFile(
                this.formUpload[i].results?.find((elm) => !elm.id)?.file
              ).then((url) => {
                console.log(this.formUpload[i]);
                let item = {
                  attachment_ids: this.formUpload[i]?.listServices?.length
                    ? this.formUpload[i]?.listServices
                    : [],
                  url: url,
                  group_name: "Phiếu kết quả",
                  // group_name: this.formUpload[i].name,
                  order_id: this.dataSelected?.order_id,
                };
                if (this.formUpload[i].id)
                  item.group_id = this.formUpload[i].id;
                attachments.push(item);
              });
            }
          }
          const params = {
            attachments: [...attachments],
            type: this.RESULT_TYPE.PROCESSOR, // type: PROCESSOR / SUPPLIER
          };
          // call api trả kết quả
          await Vue.prototype.$rf
            .getRequest("CommonRequest")
            .processorReturnResult(params)
            .then(async () => {
              // Check: nếu chưa có file kết quả nào thì sau khi call API trả KQ => call API chuyển trạng thái order_item thành đã trả KQ (status: 3)
              let listOrderItem = [];
              let listAttachmentIds = [];
              for (let i = 0; i < this.formUpload.length; i++) {
                if (this.formUpload[i].id === undefined) {
                  listOrderItem = listOrderItem.concat(
                    this.formUpload[i].order_items.map((item) => item.id)
                  );
                  listAttachmentIds = listAttachmentIds.concat(
                    this.formUpload[i].listServices
                  );
                }
              }
              if (listOrderItem.length > 0) {
                await Vue.prototype.$rf
                  .getRequest("CommonRequest")
                  .changeStatusOrderItem({
                    order_item_ids: listOrderItem,
                    order_item_attachment_ids: listAttachmentIds,
                    status: 3, // 3: status trả KQ
                    changed_at: new Date().getTime(),
                  });
              }
              // Nếu đã có file kết quả => trả KQ như bình thường, không call API chuyển trạng thái order_item
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Trả kết quả thành công",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
              await this.fetchListResult();
              this.$emit("success");
              this.isLoadingReturnResult = false;
            });
        }
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi trả kết quả",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.isLoadingReturnResult = false;
        console.log(error);
      }
    },
    // upload file
    async uploadFile(file) {
      const params = {
        file,
        FolderTarget: `certificates`,
        IdTarget: this.dataSelected?.order_id,
      };
      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }

      const res = await this.$rf
        .getRequest("CommonRequest")
        .uploadFileTenant(formData);
      return res.data.fileUrl;
    },
    // Fetch list kết quả đã trả
    async fetchListResult() {
      try {
        this.isLoadingReturnResult = true;
        const params = {
          order_id: this.dataSelected.order_id,
          page_num: 1,
          page_size: 100,
          order: "desc",
          sort_by: "created_at",
          type: this.RESULT_TYPE.PROCESSOR,
        };
        await Vue.prototype.$rf
          .getRequest("CommonRequest")
          .fetchListResult(params)
          .then((res) => {
            this.detailResult = res.data?.data?.data;
            // set form upload
            this.formUpload = this.detailResult?.map((elm) => {
              if (elm.id) {
                return {
                  id: elm.id,
                  name: elm.name,
                  results: elm.attachment_versions?.map((item) => {
                    return {
                      id: item.id,
                      url: item.url,
                      created_at: item.updated_at,
                      updated_name: item.create_by?.name,
                    };
                  }),
                  listServices: elm.order_item_attachment?.map(
                    (item) => item.id
                  ),
                  order_items: elm.order_item_attachment?.map((item) => {
                    return {
                      id: this.processorOrdersSelected?.find(
                        (p_o) => item.order_item_id === p_o.order_item_id
                      )?.id,
                      status: this.processorOrdersSelected?.find(
                        (p_o) => item.order_item_id === p_o.order_item_id
                      )?.status,
                      service_name: this.processorOrdersSelected?.find(
                        (p_o) => item.order_item_id === p_o.order_item_id
                      )?.order_item_name,
                    };
                  }),
                };
              }
            });
            // disable những dịch vụ đã tạo phiếu
            let order_items_selected = [];
            for (let i = 0; i < this.detailResult.length; i++) {
              let item = this.detailResult[i].order_item_attachment?.map(
                (elm) => elm.id
              );
              order_items_selected = order_items_selected.concat(item);
            }
            this.listDisabled = this.listServices?.filter(
              (elm) =>
                order_items_selected.includes(
                  elm.data?.order_item_attachments[0]?.ID
                ) || elm.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
            );
          });
        this.isLoadingReturnResult = false;
      } catch (error) {
        console.log(error);
      }
    },
    // open sildebar
    async handleOpen() {
      // reset data
      this.listOrders = [];
      this.dataOrders = [];
      this.pagination = {
        page_size: 10,
        page_num: this.selectedPage,
        total: 0,
      };
      this.dataOrders = this.orders.listOrdersInPage.map((elm) => {
        return {
          ...elm,
          supplier_order: {
            ...elm.supplier_order,
            result_status: elm.result_status,
            contact_name: elm?.order_contact[0]?.name,
          },
        };
      });
      this.listOrders = this.dataOrders.map((elm) => {
        return {
          ...elm.supplier_order,
          result_status: elm.result_status,
          contact_name: elm.order_contact[0]?.name,
        };
      });
      // await this.fetchOrdersList()

      //query selector
      // const listOrerScroll = document.querySelector('#orders')
      // listOrerScroll.addEventListener('scroll', async () => {
      //   if (this.pagination.page_num < this.pagination.total_page) {
      //     if (
      //       listOrerScroll.scrollTop + listOrerScroll.clientHeight >=
      //         listOrerScroll.scrollHeight &&
      //       !this.isloadingOrder
      //     ) {
      //       this.isloadingOrder = true
      //       this.pagination.page_num =
      //       this.pagination.page_num = this.pagination.page_num + 1
      //       if (this.pagination.page_num !== this.selectedPage) {
      //         await this.fetchOrdersList()
      //         this.isloadingOrder = false
      //       }
      //     }
      //   } else return
      // })
      // set data
      this.dataSelected = this.dataOrders?.find(
        (elm) => elm.supplier_order?.ID === this.orderId
      )?.supplier_order;
      this.processorOrdersSelected = this.dataOrders?.find(
        (elm) => elm.supplier_order?.ID === this.orderId
      )?.order_item;
      await this.fetchListResult();
      // let serviceRejected = this.listServices.filter(
      //   elm => elm.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL
      // )
      // this.listDisabled = this.listDisabled.concat(serviceRejected)
    },
    // fetch order list
    async fetchOrdersList(ctx, callback) {
      const userData = appUtils.getLocalUser();
      this.isloadingOrder = true;
      return await Vue.prototype.$rf
        .getRequest("CommonRequest")
        .fetchListResultAttachment({
          keyword: this.keyword,
          processor_id: userData?.processorsInfo?.id,
          sort_by: "assign_date",
          page_size: this.pagination.page_size,
          page_num: this.pagination.page_num,
          order: "desc",
        })
        .then((response) => {
          const orders = response?.data?.data?.map((elm) => {
            return {
              ...elm.supplier_order,
              result_status: elm.result_status,
              contact_name: elm.order_contact[0]?.name,
            };
          });
          this.pagination.total = response.data?.page?.total;
          this.pagination.total_page = response.data?.page?.total_page;
          this.listOrders = this.listOrders.concat(orders);
          const data_order = response.data?.data?.map((elm) => {
            return {
              ...elm,
              supplier_order: {
                ...elm.supplier_order,
                result_status: elm.result_status,
                contact_name: elm?.order_contact[0]?.name,
              },
            };
          });
          this.dataOrders = this.dataOrders.concat(data_order);
          callback && callback(orders);

          return orders;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isloadingOrder = false;
        });
    },
    // detail order
    handleView(order) {
      this.dataSelected = this.dataOrders?.find(
        (elm) => elm.supplier_order?.ID === order.ID
      )?.supplier_order;
      this.processorOrdersSelected = this.dataOrders?.find(
        (elm) => elm.supplier_order?.ID === order.ID
      )?.order_item;
      this.fetchListResult();
    },
    // format file name
    formatFileName(url) {
      let indexOfLastDot = url.lastIndexOf(".");
      let indexOfLastSlash = url.lastIndexOf("/");
      return url.slice(indexOfLastSlash + 1, indexOfLastDot);
    },
  },
};
</script>

    <style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.footer {
  border-top: 1px solid #dbdbdb;
  margin-left: 0 !important;
}

.content-form {
  background: #fff;
  padding: 16px 0;
  height: 100%;
  flex: auto;
}
.text-base {
  font-size: 16px;
  line-height: 24px;
}
.list-service {
  border-right: 1px solid #dbdbdb;
}
.delete {
  &:hover {
    color: crimson;
  }
  width: 24px;
  height: 24px;
}
.btn-cancel {
  &:hover {
    text-decoration: underline;
  }
}
::v-deep.cs-select-file {
  custom-file-label {
    font-size: 14px;
  }
}

.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.text-blue {
  color: #20419b;
}

.b-sidebar-body,
.side-bar,
.orders {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
}

.url {
  max-width: 500px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #20419b;
  text-decoration: underline;
  cursor: pointer;
}
.result-submitted {
  padding: 8px 16px;
  // border: 1px dotted;
  border-radius: 4px;
  background: #f9f9f9;
}
.side-bar {
  width: 300px;
  border-right: 1px solid #dbdbdb;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  // overflow: auto;
  padding: 16px;
  height: calc(100vh - 138px);

  .orders {
    overflow: auto;
    // height: 85%;
    .order-item {
      transition: all 0.3s ease-in-out;
      padding: 12px;
      border-top: 1px solid #dbdbdb;
      &:first-child {
        border-top: none;
      }
      .icon-view {
        display: none;
      }
      &:hover {
        cursor: pointer;
        .icon-view {
          display: flex !important;
        }
      }
    }
  }
}
.side-bar,
.orders {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
}
</style>
 <style lang="scss">
.header-slide {
  height: 80px;
  // display: flex;
  justify-content: space-between;
  flex-direction: row-reverse !important;
  padding: 8px 24px !important;
  border-bottom: 1px solid #dbdbdb;
  .close {
    margin-right: unset !important;
  }
}
.b-sidebar-body {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #0caee9;
    border-radius: 20px;
    border: 6px solid #a8bbbf;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000d0f;
  }
}
</style>
